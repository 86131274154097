<template>
  <b-card>
    <b-row class="m-1 top-header cursor-pointer">
      <div @click="goBack">
        <feather-icon icon="ArrowLeftIcon" size="24" />
      </div>
      <b-col cols="12" md="6" class="d-flex align-items-center">
        <h3 class="mb-0">Devices</h3>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-end align-items-center"
      >
      </b-col>
    </b-row>
    <div v-if="deviceList && deviceList.length > 0">
      <div v-for="device in deviceList" :key="device">
        <div class="device-item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="">
              <div>{{ device.device_id }}</div>
            </div>
            <b-button
              class="primary badge-light-success p-1"
              v-b-modal.modal-confirmation
              v-on:click="() => setDeviceID(device.device_id)"
            >
              Attach
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex flex-column align-items-center py-5">
      <b-img
        :src="
          require('@/assets/images/svg/infographics/no_data_infographic.svg')
        "
        class="mr-2"
      />
      <h4 class="mt-2 Secondary">No Device Available</h4>
    </div>
    <!-- add device -->
    <b-modal
      id="add-device"
      title="Add Device"
      ok-only
      ok-title="Add"
      @ok="addDevice"
      ref="my-modal"
    >
      <b-form method="post">
        <b-form-group label="Enter Device Id">
          <div class="form-label-group">
            <b-form-input
              name="DeviceId"
              v-model="DeviceId"
              placeholder="Enter Device Id"
              type="text"
            />
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- confirmation modal -->
    <b-modal
      id="modal-confirmation"
      title="Add Machine"
      ok-only
      class="primary badge-light-success p-1"
      ok-title="Add"
      @ok="() => attachDevice()"
    >
      <b-card-text>
        Are you sure, you want to attach this device ?
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormDatepicker,
  BImg,
} from "bootstrap-vue";

import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BTooltip,
    BFormDatepicker,

    BImg,
  },
  data() {
    return {
      DeviceId: null,
      deviceList: [],
    };
  },
  created() {
    this.getDeviceList();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    moment() {
      return moment();
    },
    getDeviceList() {
      const hatchery_id = this.$route.params.farmId;
      axiosIns
        .get(`web/hatchery/${hatchery_id}/get-all-device-list`)
        .then((response) => {
          this.deviceList = response.data.deviceList;
        })
        .catch((error) => {
          error;
        });
    },

    attachDevice() {
      const hatchery_id = this.$route.params.farmId;
      const machineId = this.$route.params.machineId;
      const deviceId = localStorage.getItem("deviceId");
      axiosIns
        .post(
          `web/hatchery/${hatchery_id}/Machine/${machineId}/change-device/${deviceId}`
        )
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "success",
              text: `data Successfully Added `,
            },
          });
          localStorage.removeItem("deviceId");
          this.goBack();
        })
        .catch((error) => {
          const data = error.response.data.message;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
        });
    },

    setDeviceID(deviceId) {
      localStorage.setItem("deviceId", deviceId);
    },
  },
};
</script>

<style lang="scss" scoped>
.device-item {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
  padding: 1rem 1.5rem 1rem 1.3rem;
  margin: 1rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
